import "./navbar.css"

import { useRef } from 'react';

import { Image } from 'primereact/image';

import { Button } from 'primereact/button';

import { Menu } from 'primereact/menu';

import { Toast } from 'primereact/toast';

        

export default function Navbar() {

    const socialLinks = {
        "whatsapp": "",
        "instagram": "https://www.instagram.com/thionmusic/",
        "twitter": "https://twitter.com/thionmusic",
        "telegram": "https://t.me/thionmusic"
    }

    const toast = useRef(null);
    const menuRight = useRef(null);
    const items = [
        {
            label: 'Navigates',
            items: [
                {
                    label: 'Home',
                    icon: 'pi pi-home',
                    url: "#home"
                },
                {
                    label: 'About',
                    icon: 'pi pi-users',
                    url: "#about"
                },
                {
                    label: 'Partners',
                    icon: 'pi pi-sitemap',
                    url: "#partners"
                }
            ]
        },
        {
            label: 'Social',
            items: [
                {
                    label: 'WhatsApp',
                    icon: 'pi pi-whatsapp',
                    url: socialLinks["whatsapp"]
                },
                {
                    label: 'Instagram',
                    icon: 'pi pi-instagram',
                    url: socialLinks["instagram"]
                },
                {
                    label: 'Twitter',
                    icon: 'pi pi-twitter',
                    url: socialLinks["twitter"]
                },
                {
                    label: 'Telegram',
                    icon: 'pi pi-telegram',
                    url: socialLinks["telegram"]
                }
            ]
        }
    ];
    return (
        <>
        <div className="navbarpc">
            <div style={{height: "90px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr"}}>
              <Image style={{display: "flex", justifyContent: "center", alignItems: "center" }} src="logo-bg-transparent.png" alt="Image" width="250" />
              <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button onClick={() => window.location.href = "#home"} style={{height: "50px", color: "white", margin: "5px", borderColor: "#b57a36"}} label="Home" rounded outlined  />
                <Button onClick={() => window.location.href = "#about"} style={{height: "50px", color: "white", margin: "5px", borderColor: "#b57a36"}} label="About" rounded outlined  />
                <Button onClick={() => window.location.href = "#partners"} style={{height: "50px", color: "white", margin: "5px", borderColor: "#b57a36"}} label="Partners" rounded outlined  />
              </div>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>

                <span onClick={() => window.location.href = socialLinks.whatsapp} className="alink pi pi-whatsapp"      style={{cursor: "pointer", fontSize: '1.5rem', margin: "10px", textDecoration: "none" }}></span>
                <span onClick={() => window.location.href = socialLinks.instagram} className="alink pi pi-instagram"    style={{cursor: "pointer", fontSize: '1.5rem', margin: "10px", textDecoration: "none" }}></span>
                <span onClick={() => window.location.href = socialLinks.twitter} className="alink pi pi-twitter"        style={{cursor: "pointer", fontSize: '1.5rem', margin: "10px", textDecoration: "none" }}></span>
                <span onClick={() => window.location.href = socialLinks.telegram} className="alink pi pi-telegram"      style={{cursor: "pointer", fontSize: '1.5rem', margin: "10px", textDecoration: "none" }}></span>
              </div>
            </div>
        </div>

        <div className="navbarmobile">
            <div style={{height: "55px", position: "relative"}}>
              <img style={{width: "150px", height: "55px", objectFit: "cover", position: "absolute", left: "50%", top: "50%", transform: 'translate(-50%,-50%)'}} src="logo-bg-transparent.png" alt="Thion Logo" />

              <div style={{position: "absolute", right: "5px", top: "50%", transform: 'translatey(-50%)'}}>
                <Toast ref={toast}></Toast>
                <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" style={{color: "#b57a36"}} />
                <Button icon="pi pi-align-right" className="navbarbutton mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
              </div>
            </div>
        </div>
        </>
      )
}