import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";     
import 'primeicons/primeicons.css';
import "./index.css"
import "./portal.css"

import Navbar from "./components/Navbar";      
import { Button } from 'primereact/button';  

function App() {

  return (
    <div id="background" style={{width: "100vw", height: "100vh", position: "absolute", left: "0", top: "0", overflowY: "scroll"}}>
      <Navbar />
    
      <div id="home">
        <img src="/background.png" alt="Background" style={{position: "absolute", left: "50%", transform: "translateX(-50%)", width: "100vw", height: "100vh", objectFit: "cover"}} />
        <div style={{backgroundColor: "rgba(0,0,0,.7)", height: "100vh", width: "100vw", position: "absolute", left: "0", top: "0"}}></div>
        <span className="title">Distribute Your Music <span style={{color: "#b57a36"}}>Worldwide</span>
        <br></br>
        <p className="titlealt">We contribute to your career with the best distribution strategy and marketing for independent and professional artists.</p>
        <Button onClick={() => window.location.href = "#contact"} className="contactbutton" style={{backgroundColor: "#b57a36"}} label="Contact" rounded />
        </span>

        <a href="#about" class="mouse-wrapper">
          <span>Scroll to About Section</span>
          <span class="mouse">
            <span class="wheel"></span>
          </span>
        </a>
      </div>

      
      <div id="about" style={{width: "100vw", height: "100vh", marginTop: "100vh", position: "relative"}}>

        <span className="title1">About <span style={{color: "#b57a36"}}>Thion Music</span></span>

        <div className="aboutsection">
          <img src="about.png" alt="About Section" style={{margin: "auto", objectFit: "contain"}} />

          <div className="aboutinfo">
            <div className="aboutchild">
              <h1>Unlimited Worldwide Music Distribution</h1>
              <p>
                We believe there is the right audience for every business. We help bring your work and your target audience 
                together by distributing your work worldwide through more than 20 platforms worldwide.
              </p>
            </div>
            <div className="aboutchild">
              <h1>Unlimited Releases to Social Platforms</h1>
              <p>
                Get your music on TikTok, YouTube, 
                Instagram, Facebook, Reels and for no annual fee.
              </p>
            </div>
            <div className="aboutchild">
              <h1>Report Transparency</h1>
              <p>
                You get what you deserve. Thanks to the convenience and transparency of reports 
                from us, you no longer have to worry about the transparency of the royalties you receive.
              </p>
            </div>
          </div>
        </div>

        <a href="#partners" class="mouse-wrapper">
          <span>Scroll to Partners Section</span>
          <span class="mouse">
            <span class="wheel"></span>
          </span>
        </a>
      </div>

      <div id="partners" style={{width: "100vw", height: "100vh", position: "relative"}}>
        <span className="title2">Our <span style={{color: "#b57a36"}}>Partners</span></span>

        <p className="paragraph">Distribute your music to over 20 platforms for free!</p>

        <div className="partnersinfo">
          <div class="logos">
            <div class="logos-slide">
              <img src="./partners/spotify.png" alt="Partner Logo" />
              <img src="./partners/youtube.png" alt="Partner Logo" />
              <img src="./partners/applemusic.png" alt="Partner Logo" />
              <img src="./partners/amazon.png" alt="Partner Logo" />
              <img src="./partners/tiktok.png" alt="Partner Logo" />
              <img src="./partners/instagram.png" alt="Partner Logo" />
              <img src="./partners/deezer.png" alt="Partner Logo" />
              <img src="./partners/resso.png" alt="Partner Logo" />
              <img src="./partners/tidal.png" alt="Partner Logo" />
              <img src="./partners/snapchat.png" alt="Partner Logo" />
              <img src="./partners/anghami.png" alt="Partner Logo" />
              <img src="./partners/ayoba.png" alt="Partner Logo" />
              <img src="./partners/boomplay.png" alt="Partner Logo" />
              <img src="./partners/jaxsta.png" alt="Partner Logo" />
              <img src="./partners/joox.png" alt="Partner Logo" />
              <img src="./partners/napster.png" alt="Partner Logo" />
              <img src="./partners/netease.png" alt="Partner Logo" />
              <img src="./partners/pandora.png" alt="Partner Logo" />
              <img src="./partners/qobuz.png" alt="Partner Logo" />
            </div>

            <div class="logos-slide">
              <img src="./partners/spotify.png" alt="Partner Logo" />
              <img src="./partners/youtube.png" alt="Partner Logo" />
              <img src="./partners/applemusic.png" alt="Partner Logo" />
              <img src="./partners/amazon.png" alt="Partner Logo" />
              <img src="./partners/tiktok.png" alt="Partner Logo" />
              <img src="./partners/instagram.png" alt="Partner Logo" />
              <img src="./partners/deezer.png" alt="Partner Logo" />
              <img src="./partners/resso.png" alt="Partner Logo" />
              <img src="./partners/tidal.png" alt="Partner Logo" />
              <img src="./partners/snapchat.png" alt="Partner Logo" />
              <img src="./partners/anghami.png" alt="Partner Logo" />
              <img src="./partners/ayoba.png" alt="Partner Logo" />
              <img src="./partners/boomplay.png" alt="Partner Logo" />
              <img src="./partners/jaxsta.png" alt="Partner Logo" />
              <img src="./partners/joox.png" alt="Partner Logo" />
              <img src="./partners/napster.png" alt="Partner Logo" />
              <img src="./partners/netease.png" alt="Partner Logo" />
              <img src="./partners/pandora.png" alt="Partner Logo" />
              <img src="./partners/qobuz.png" alt="Partner Logo" />
            </div>

          </div>
        </div>


        <a href="#contact" class="mouse-wrapper">
          <span>Scroll to Contact Section</span>
          <span class="mouse">
            <span class="wheel"></span>
          </span>
        </a>
      </div>

      <div id="contact" style={{width: "100vw", height: "100vh", position: "relative"}}>
        <span className="title3">Contact</span>

        <div className="contactform">
          <input className="contactinput" placeholder="Full Name" />
          <br />
          <input className="contactinput" placeholder="E-Mail" />
          <br />
          <input className="contactinput" placeholder="Phone" />
          <br />
          <textarea className="contactinput" placeholder="Message" rows={10} />

          <Button className="contactsubmit" style={{backgroundColor: "#b57a36"}} label="Send Message" rounded />
        </div>

      </div>

      </div>
  );
}

export default App;
